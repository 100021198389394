import { useEffect, useState } from "preact/hooks"
import { activeProvider } from "./activeProvider"
interface User {
  name: string
  email: string
  password: string
  customer?: string
}
const saveUser = async (user: User) => {
  const body = JSON.stringify(user)
  const headers = { "Content-Type": "application/json" }
  const resp = await fetch("/api/userDetails", {
    method: "POST",
    body,
    headers
  })
  if (resp.status >= 300) {
    const err = await resp.text()
    throw new Error(err)
  }
  return resp.json()
}

const logout = () => {
  fetch("/api/logout").then(() => location.reload())
}

enum LoadState {
  Loading,
  Loaded,
  Failed
}
export const LoginForm = () => {
  const [loaded, setLoaded] = useState(LoadState.Loading)
  const [registered, setRegistered] = useState(false)
  const [error, setError] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [customer, setCustomer] = useState("")
  const [password, setPassword] = useState("")
  const [entitlements, setEntitlements] = useState("")
  const [sessionType, setSessionType] = useState("")

  const onSubmit = async (event: any) => {
    try {
      event.preventDefault()
      const resp = await saveUser({ name, email, password, customer })
      setError("")
      if (resp.email) setRegistered(true)
      setEntitlements(resp.entitlements)
    } catch (error) {
      setError(`${(error as any)?.message || error}`)
    }
  }
  useEffect(() => {
    activeProvider().then(provider => {
      if (provider.outcome === "Error") {
        setError(provider.message)
        setLoaded(LoadState.Failed)
      } else {
        const user = provider.user
        setSessionType(provider.session)
        if (user.email) setEmail(user.email)
        if (user.name) setName(user.name)
        setCustomer(user.CUSTOMERID)
        if (user.email && user.name) setRegistered(true)
        setEntitlements(user.entitlements || "")
        setLoaded(LoadState.Loaded)
      }
    })
  }, [])

  const entstate = () => {
    if (!registered || sessionType !== "AWS") return <></>
    return (
      <div>{entitlements ? "Entitlements found" : "No entitlements found"}</div>
    )
  }
  if (loaded === LoadState.Loading) return <div>{"Loading..."}</div>
  if (loaded === LoadState.Failed)
    return (
      <div>
        {"Invalid session ID, please go back to the AWS/MS store"}
        {error ? <div>{error}</div> : <></>}
      </div>
    )

  return (
    <>
      {error ? <div>{error}</div> : <></>}
      <form onSubmit={onSubmit}>
        <label for="name">Username: </label>
        <input
          name="name"
          disabled={registered}
          type="text"
          value={name}
          onInput={e => setName((e.target as any).value)}
        />
        <label for="email">email: </label>
        <input
          name="email"
          disabled={registered}
          type="text"
          value={email}
          onInput={e => setEmail((e.target as any).value)}
        />
        <input name="customer" type="hidden" value={customer} />
        {registered ? (
          "User successfully registered. Please contact Basis Technologies for support or reviewing your contract details"
        ) : (
          <>
            <label for="password">password: </label>
            <input
              name="password"
              type="password"
              value={password}
              onInput={e => setPassword((e.target as any).value)}
            />
            <button type="submit">Submit</button>
          </>
        )}
        {entstate()}
      </form>
    </>
  )
}
