import Cookies from "js-cookie"
import * as t from "io-ts"
import { errorMessage, mixed, validate } from "../../utils"
const userC = mixed(
  {
    ID: t.string,
    CUSTOMERID: t.string,
    CUSTOMERACCOUNTID: t.string,
    PRODUCTCODE: t.string
  },
  {
    name: t.string,
    email: t.string,
    entitlements: t.string
  }
)

const urlC = t.type({
  url: t.string
})

interface ProviderError {
  session: "aws" | "ms" | string | undefined
  outcome: "Error"
  message: string
}

interface ProviderOk {
  session: "aws" | "ms"
  outcome: "Ok"
  user: t.TypeOf<typeof userC>
}

export type Provider = ProviderError | ProviderOk // | ProviderMsOk

export const activeProvider = async (): Promise<Readonly<Provider>> => {
  const session = Cookies.get("sessiontype") || "ms"
  try {
    const message = new URLSearchParams(window.location.search).get("error")
    if (message) return { session, outcome: "Error", message }
    const resp = await fetch("/api/userDetails")
    if (resp.status >= 300) {
      const err = await resp.text()
      throw new Error(err)
    }
    const user = validate(userC, await resp.json())
    if (session == "aws" || session === "ms") {
      return { session, outcome: "Ok", user }
    }
    return { session, outcome: "Error", message: "invalid session" }
  } catch (error) {
    const message = errorMessage(error)
    return { session, outcome: "Error", message }
  }
}
