import { useState } from 'preact/hooks';
import basisLogo from '../../../public/Basis_logo.svg';
import './style.css';
import { LoginForm } from './LoginForm';

export function Home() {
	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [passwd, setPassword] = useState("")
	const onSubmit = () => { alert("hi") }
	return (
		<div class="home">
			<a href="https://www.basistechnologies.com" target="_blank"> <img src={basisLogo} alt="Preact logo" height="160" width="160" /> </a>
			<LoginForm />
			<section>
				<Resource
					title="Basis Technologies"
					description="The world’s best-performing organizations trust our industry-leading tools to power change."
					href="https://www.basistechnologies.com"
				/>
				<Resource
					title="ActiveControl"
					description="ActiveControl empowers the world’s best-performing organizations to embrace change in SAP "
					href="https://www.basistechnologies.com/solutions/activecontrol"
				/>
			</section>
		</div>
	);
}

function Resource(props:any) {
	return (
		<a href={props.href} target="_blank" class="resource">
			<h2>{props.title}</h2>
			<p>{props.description}</p>
		</a>
	);
}
