import * as t from "io-ts"
import reporter from "io-ts-reporters"

export const validate = <A, I = unknown>(decoder: t.Decoder<I, A>, data: I): A => {
    const decoded = decoder.decode(data)
    if (decoded._tag === "Right") return decoded.right
    const errors = reporter.report(decoded)
    throw new Error(errors.join("\n"))
}

export function mixed<R extends t.Props, O extends t.Props>(
    required: R,
    optional: O
): t.IntersectionC<[t.TypeC<R>, t.PartialC<O>]> {
    return t.intersection([t.type(required), t.partial(optional)])
}

export const errorMessage = (e: unknown) => e instanceof Object && "message" in e && typeof e.message === "string" ? e.message : `${e}`